import React from 'react';

const ContactEmail = () => {
	const coded = "pDYZhTwT1Zr@8wrZ5.KDw";
	const key = "F0SikcMUGefqwadgtADnv6j7ZBKLXyTINlr18PCRhYxsJ2Q34uzWVm5pHbEo9O";
	const shift = coded.length;
	var link = "";
	var ltr;
	for (var i = 0; i < coded.length; i++) {
		if (key.indexOf(coded.charAt(i)) === -1) {
			ltr = coded.charAt(i);
			link += (ltr);
		}
		else {
			ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length;
			link += (key.charAt(ltr));
		}
	}
	const href = `mailto:${link}`;
	return (
		<>
			<a href={href}>contact us</a>
			<span> at </span>
			<b>{link}</b>
		</>
	);
};


export default ContactEmail;