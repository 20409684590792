import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ContactEmail from '../../components/contact-email';


const PageContent = () => (
	<div className="container">

		<div className="card">
			<div className="card-header">
				<h1>Right News - Support</h1>
			</div>

			<div className="card-body">

				<h2>Main Menu</h2>
				<p>
					To access the app's menu, press the "hamburger" icon in the top-right.
				</p>
				<p>
					<img src="/images/right-news/menu-button.png" alt="hamburger menu button" />
				</p>
				<p>The options below will be revealed. Tap on any of them to access the corresponding option.</p>
				<p>
					<img src="/images/right-news/main-menu.png" alt="app main menu" style={{ maxWidth: '100%' }} />
				</p>


				<h2>Articles</h2>
				<p>The Articles section shows the most important and the most read articles, by default sorted based on a composite score.</p>
				<p>You can sort them chronologically, by clicking on the top-right sort button.</p>

				<h2>Podcasts</h2>
				<p>
					The Podcasts section shows the most recent podcasts, sorted by date published.
					To play a podcast, just tap on its card and the podcast player will be displayed at the bottom of the screen.
				</p>

				<h2>Videos</h2>
				<p>
					The Videos section shows the most recent videos, sorted by date published.
					To play a video, just tap on its card and the video will open in an external app, like YouTube, or in the browser.
				</p>

				<h2>Saved and Favorites</h2>
				<p>
					To add an article to the <i>Saved</i> or <i>Favorites</i> section, tap the 3-dot button shown in the bottom right of an article card.
					Then, from the context menu that appears, tap on <i>Save for later</i> or <i>Add favorite</i>.
				</p>


				<h2>Contact Us</h2>
				<p>
					<span>If you have any questions or suggestions regarding the app or our news sources, do not hesitate to </span>
					<ContactEmail />
					.
				</p>

			</div>

		</div>

	</div>

);


const Support = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="Right News - Privacy Policy" />
			<PageContent />
		</Layout>
	);
}


export default Support;


export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
